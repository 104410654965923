body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.my-d {
  display: flex;
}

@media only screen and (max-width: 575px) {
  .my-d {
    display: block;
  }
  .btn {
    width: 100%;
  }
  .my-w-200 {
    height: 300px !important;
  }
  .my-w-100 {
    width: 100% !important;
  }
}
@media only screen and (max-width: 992px) {
  .sidebar {
    width: 240px;
  }
}

@media only screen and (max-width: 469px) {
  .my-container {
    margin-top: 100px;
  }
}

@media only screen and (max-width: 309px) {
  .my-container {
    margin-top: 150px;
  }
}
@media only screen and (max-width: 400px) {
  .px-5 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
.loader {
  border: 12px solid #e3e3e3;
  border-radius: 50%;
  border-top: 16px solid #4070f4;
  width: 100px;
  height: 100px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}